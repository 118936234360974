import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "TeamColor",
  code: "TeamColor"
};
export const _frontmatter = {
  "menuLabel": "Team Color",
  "sortOrder": 20.1
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LivePlayround = makeShortcode("LivePlayround");
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Team Color`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-team-color--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <p>{`The Team Color utility component provides a versatile way to enhance elements with the vibrant and distinct colors of sports teams. It enables you to blend two primary colors from two different teams together, creating a unique gradient effect that can be customized further. `}</p>
    <p>{`You can also apply a dark or light screen over the colors to achieve greater contrast and customization, ensuring that the design aligns perfectly with your application's visual identity. The component supports various gradient directions, including horizontal, diagonal, and vertical, giving you the flexibility to create dynamic and visually appealing designs. `}</p>
    <p>{`This utility is ideal for use in sports-related applications, allowing for a personalized and engaging user experience that reflects team branding.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      